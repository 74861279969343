<template>
	<div class="layout-topbar">
		<router-link to="/" class="layout-topbar-logo">
			<img alt="Logo" src="./assets/logo-light-128.png" height="4.5rem"/>
		</router-link>
		<button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
			<i class="pi pi-bars"></i>
		</button>
		<div class="layout-topbar-menu lg:flex origin-top">
			<Button class="p-button-rounded p-button-plain p-button-text" icon="pi pi-user" type="button" label="Profile" @click="toggle" aria-haspopup="true" aria-controls="overlay_tmenu"/>
			<TieredMenu id="overlay_tmenu" ref="menu" :model="items" :popup="true" />
		</div>

		<Dialog header="Sign Out Confirmation" v-model:visible="displaySignOut"  :modal="true" :breakpoints="{'960px': '75vw'}" :style="{width: '30vw'}">
            <div class="flex p-4">
				<i class="pi pi-info-circle" style="font-size: 1.5rem"></i>
				<p class="pl-2">Do you want to Sign Out?</p>
			</div>
            <template #footer>
                <Button label="No" icon="pi pi-times" @click="closeSignOutToggle" class="p-button-text"/>
                <Button label="Yes" icon="pi pi-check" @click="signout" autofocus class="p-button-raised p-button-danger"/>
            </template>
        </Dialog>		
	</div>
</template>

<script>
import { ref } from "vue";
import Axios from 'axios';
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
	setup(){
		const store = useStore();
		const router = useRouter();
		
		let user = ref();
		const displaySignOut = ref(false);

		const menu = ref();

		const items = ref([
			{
				label: 'Profile',
				icon: 'pi pi-user',
				command: () => {
					router.replace({name:'account'});
				}
			},
			{
				label: 'Agency',
				icon: 'pi pi-building',
				command: () => {
					router.replace({name:'agency'});
				}
			},
			{
				label: 'Sign Out',
				icon: 'pi pi-sign-out',
				command: () => {
					displaySignOut.value = true;
				}
			},
		]);

        const toggle = (event) => {
            menu.value.toggle(event);
        }

		const getUserData = () => {
			Axios
				.get("api/user")
				.then((res) => {
					if(res.data.response == "success"){
					user.value = res.data.data;
					}
				})
				.catch((err) => {
					console.log(err);
				});
		};
		getUserData();

		const openSignOutToggle = () => {
            displaySignOut.value = true;
        };
		
        const closeSignOutToggle = () => {
            displaySignOut.value = false;
        };

		const signout = () => {
			store.dispatch('removeUserData');
			router.push({name:'signin'});
		};


		return {
		user,
		getUserData,
		signout,
		displaySignOut,
		openSignOutToggle,
		closeSignOutToggle,
		items,
		toggle,
		menu
		}
	},
    methods: {
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
		onTopbarMenuToggle(event) {
            this.$emit('topbar-menu-toggle', event);
        },
		topbarImage() {
			return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg';
		}
    },
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		}
	}
}
</script>
<style scoped>
.layout-topbar-logo {
	width: 135px !important;
}
</style>