<template>
	<div class="layout-footer">
		<img alt="Logo" class="mr-2" src="./assets/logo-light-128.png" height="50"/>
		<span class="mt-4">a sister concern of
		<a href="https://shopnosoft.com" target="_blank" class="cursor-pointer">
			<span class="font-medium ml-2">ShopnoSoft</span>
		</a></span>
	</div>
</template>

<script>
	export default {
		name: "AppFooter",
		methods: {
			footerImage() {
				return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg';
			}
		},
		computed: {
			darkTheme() {
				return this.$appState.darkTheme;
			}
		}
	}
</script>